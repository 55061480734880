import Validator from "@/vendor/validate-us.js";
import $ from "jquery";
import Vue from 'vue'

const propNotSetOrIsTrue = (object, propertyName) => {
  return !object.hasOwnProperty(propertyName) || (object.hasOwnProperty(propertyName) && object[propertyName]);
 }

function isEmptyObject(obj) {
  for(var prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return false;
    }
  }
  return true;
}

export default {
  namespaced: true,
  state: {
    title: "",
    activeStep: 0,
    plugins: [],
    data: null,
    steps: []
  },
  getters: {
    activeStep: state => state.activeStep,
    progress: (state, getters) => {
      return parseInt((state.activeStep / getters.visibleSteps.length) * 100);
    },
    data: state => state.data,
    visibleSteps: (state) => {
      let visible_stages = state.steps.filter((step) => {
        return propNotSetOrIsTrue(step, "visible");
      });
      visible_stages.forEach(function(stage, index){
        stage.index = index; // reindex
        //Возможно тут тоже надо будет менять мутации на полях...
      });
      return visible_stages;
    },
    lastStepIndex: (state, getters) => {
      return getters.visibleSteps.at(-1).index;
    },
    fieldData: (state) => (id) => {
      return state.data[id];
    }
  },
  mutations: {
    setState(state, props = {}) {
      Object.keys(props).forEach(key => {
        state[key] = props[key];
      });
      //изменяем шаги сразу после загрузки данных формы - некоторые из них могут быть скрыты
      let form_data = state.data;
      state.steps.forEach((stage, index)=>{
        stage.fields.forEach((field) => {
          if(field.hasOwnProperty("mutateStages") && field.mutateStages){
            //здесь только с полным именем модуля Vuex!
            this.commit("form/toggleSteps", {
              stages: field.mutateStages,
              value: form_data[field.id].value,
            });
          }
        })
      });
    },
    setValue(state, data) {
      const field = state.data[data.id]; // присваиваем ссылку
      if (typeof field === "object" && "value" in field) {
        // проверяем, сложный ли у нас объект
        if (data.isBlur) {
          const allowOther = state.data[data.id]["allowOther"]; // если да, проверяем, есть ли у нас параметр для разрешения иных значенийв поле, кроме опциональных
          //console.log('allow =>',allowOther && typeof allowOther !== "undefined");

          if (allowOther && typeof allowOther !== "undefined") {
            field.value = data.value; // если да, ставим просто значение и все
          } else if (typeof field.options !== "undefined") {
            
            // а иначе проверяем
            let options = field.options;
            if(typeof field.options === 'string'){
              options = field.options.split(",");
            }
            
            //console.log(options);
            if (options.indexOf(data.value) > -1) {
              field.value = data.value;
            } else {
              field.value = "";
            }
          } else if (field.template == "split") { //Если шаблон "split", параметра options не будет, но значение ставить надо
            field.value = data.value;
          } else {
            field.value = "";
          }
        } else {
          field.value = data.value;
        }
      } else {
        state.data[data.id] = data.value;
      }
    },
    setOptions(state, data){
      //для реализации поиска по dadata в обход параметров в JSON услуг
      let field = state.data[data.id] // присваиваем ссылку
      if(typeof field != 'object'){
        const fieldValue = field;
        state.data[data.id] = {
          value: fieldValue,
          options: data.options.map((item)=>item.hasOwnProperty("label") ? item.label : item),
        };
      }
      else{
        field.options = data.options.map((item)=>item.hasOwnProperty("label") ? item.label : item);
      }

      state.data[data.id].optionsData = data.options;

      //принужительно включаем allowInput, иначе если очень быстро ввести данные и скликнуть мимо,
      //то всё стирается
      state.data[data.id].allowOther = true;

      //принудительно открываем селект
      const event = new CustomEvent("toggle-options", { detail: data.id });
      document.dispatchEvent(event);
    },
    setFieldNotRequired(state, data){
      // присваиваем ссылку
      let field = undefined;
      state.steps.forEach((step)=>{
        step.fields.forEach((fieldData)=>{
          if(fieldData.id === data.id){
            field = fieldData;
          }
        });
      });

      if(field){
        if(field.hasOwnProperty("validate") && field.validate && field.validate.hasOwnProperty("required")){
          delete field.validate.required;
        }
        if(isEmptyObject(field.validate)){
          delete field.validate;
        }
      }
    },
    setFieldRequired(state, data){
        // присваиваем ссылку
        let field = undefined;
        state.steps.forEach((step)=>{
          step.fields.forEach((fieldData)=>{
            if(fieldData.id === data.id){
              field = fieldData;
            }
          });
        });

        if(field){
          if(field.hasOwnProperty("validate") && field.validate){
            if(field.validate.hasOwnProperty("required")){
              return;
            }

            field.validate.required = {
              error:"Данное поле обязательно для заполнения",
            }
          }
          else{
            field.validate = {
              required: {
                error:"Данное поле обязательно для заполнения",
              }
            };
          } 
        }
    },
    showErrors(state, id) {
      const found = state.steps[state.activeStep].fields.filter(
          field => field.id == id
      );

      if (found.length > 0) {
        found[0].showError = true;
      }
    },
    hideErrors(state, id) {
      const found = state.steps[state.activeStep].fields.filter(
          field => field.id == id
      );

      if (found.length > 0) {
        found[0].showError = false;
      }
    },
    validateFields(state, data) {
      const active = state.activeStep;
      const step = state.steps.filter(step => step.index == active);

      let fields = step[0].fields.filter(field => {
        for (let i = 0; i < data.fields.length; i++) {
          if (field.id == data.fields[i]) {
            return field;
          }
        }
      });

      let fieldsValid = true;

      fields.map(function(field) {
        let value = "";

        if (typeof state.data[field.id] === "object") {
          value = state.data[field.id].value;
        } else {
          value = state.data[field.id];
        }
        // если есть привязка к другому полю с датой, то добавляем к отслеживанию значение того поля
        let refDateValue;
        if (field.validate?.is?.lesserThanDate) {
          if (
            typeof state.data[
              field.validate?.is?.lesserThanDate
            ].value !== "undefined"
          ) {
            refDateValue =
              state.data[
                field.validate?.is?.lesserThanDate
              ].value;
          } else {
            refDateValue =
              state.data[
              field.validate?.is?.lesserThanDate
              ];
          }
        }
        if (field.validate?.is?.greaterThanDate) {
          if (
            typeof state.data[
              field.validate?.is?.greaterThanDate
            ].value !== "undefined"
          ) {
            refDateValue =
              state.data[
                field.validate?.is?.greaterThanDate
              ].value;
          } else {
            refDateValue =
              state.data[
              field.validate?.is?.greaterThanDate
              ];
          }
        }

        let f = Object.assign(
          { ...field },
          { value: value },
          { refDateValue: refDateValue }
        );
        let error = Validator.validateField(f);

        if (error !== true) {
          fieldsValid = false;
          field.showError = true;
        } else {
          field.showError = false;
        }
      });

      if (!fieldsValid) {
        throw "Error";
      }
    },
    validateStep(state, callback) {
      let stepValid = true;

      state.steps = state.steps.map(step => {
        if (step.index == state.activeStep) {
          return {
            ...step,
            fields: step.fields.map(field => {
              if (
                  typeof field.visible === "undefined" ||
                  field.visible == true
              ) {
                let value = "";

                if (typeof state.data[field.id] === "object") {
                  value = state.data[field.id].value;
                } else {
                  value = state.data[field.id];
                }

                // если есть привязка к другому полю с датой, то добавляем к отслеживанию значение того поля
                let refDateValue;
                if (field.validate?.is?.lesserThanDate) {
                  if (
                    typeof state.data[
                      field.validate?.is?.lesserThanDate
                    ].value !== "undefined"
                  ) {
                    refDateValue =
                      state.data[
                        field.validate?.is?.lesserThanDate
                      ].value;
                  } else {
                    refDateValue =
                      state.data[
                      field.validate?.is?.lesserThanDate
                      ];
                  }
                }
                if (field.validate?.is?.greaterThanDate) {
                  if (
                    typeof state.data[
                      field.validate?.is?.greaterThanDate
                    ].value !== "undefined"
                  ) {
                    refDateValue =
                      state.data[
                        field.validate?.is?.greaterThanDate
                      ].value;
                  } else {
                    refDateValue =
                      state.data[
                      field.validate?.is?.greaterThanDate
                      ];
                  }
                }

                let f = Object.assign(
                  { ...field },
                  { value: value },
                  { refDateValue: refDateValue }
                );

                let valid = Validator.validateField(f);

                if (valid !== true) {
                  stepValid = false;
                  field.showError = true;
                } else {
                  field.showError = false;
                }
              }

              return field;
            }),
            valid: stepValid
          };
        }

        return step;
      });

      if (stepValid) {
        callback.success();
      } else {
        callback.error();
      }
    },
    nextStep(state) {
      const $root = $("body,html");

      if (state.activeStep < state.steps.length - 1) {
        state.activeStep++;
      }

      $root.animate({ scrollTop: 0 }, 200);
    },
    prevStep(state) {
      const $root = $("body,html");

      if (state.activeStep > 0) {
        state.activeStep--;
      }

      $root.animate({ scrollTop: 0 }, 200);
    },
    toggleSteps(state, data){
      data.stages.map(function(stepToMutate){
        //совпало значение поля
        if(stepToMutate.value == data.value){
          state.steps.forEach(function(step, index) {
            if(stepToMutate.index === step.index){
              //изменяем шаг
              let mutationData = stepToMutate.mutate;
              Vue.set(state.steps, index, {...step, ...mutationData});
              // stage = ;
            }
          });
        }
        // надо выключить шаг если значение иное
        // else{
        //   state.form.stages.forEach(function(stage) {
        //     if(stageToMutate.index === stage.index){
        //       //изменяем шаг
        //       let mutationData = stageToMutate.mutate;
        //       mutationData.visible = false;
        //       stage = {...stage, ...mutationData};
        //     }
        //   });
        // }
      });
    },
    toggleFields(state, data) {
      data.fields.map(field => {
        if (field.value == data.value) {
          state.steps.map(step => {
            step.fields.map(item => {
              if (item.id == field.id) {
                if (typeof state.data[field.id] == "object") {
                  state.data[field.id].value = ""
                } else if ((typeof state.data[field.id] == "string")) {
                  state.data[field.id] = "";
                }
                item.visible = false;
              }
            });
          });
        } else {
          state.steps.map(step => {
            step.fields.map(item => {
              if (item.id == field.id) {
                if (Object.prototype.hasOwnProperty.call(field, 'validate')) {
                  item.validate = field.validate;
                }

                //вырубаем дочерние чекбоксы перед показом снова
                if(typeof state.data[field.id] == "object" && state.data[field.id].type == "CheckboxField"){
                  this.commit("form/setValue", {
                    id: item.id,
                    value: false,
                  });
                }

                item.visible = true;
              }
            });
          });
        }
      });
    },
    mutateField(state, {id, value, mutate = null, sourceId = null, setValue = true} = {}) {
      if (state.data[sourceId].value !== value) {
        return;
      }

      let field = null;

      state.steps.map(function (step) {
        step.fields.map((item) => {
          if (item.id == id) {
            field = item;
          }
        })
      })
      if (!field) {
        return;
      }

      for (const mutateProp in mutate) {
        if (mutateProp === 'value' && setValue) {
          if (typeof state.data[id] === "object") {
            state.data[id] = mutate.value;
          } else {
            state.data[id] = mutate.value;
          }

          continue;
        }

        field[mutateProp] = mutate[mutateProp];
      }
    },
    updateFieldValidation(state, { id, validate }) {
      state.steps = state.steps.map(step => {
        if (step.index == state.activeStep) {
          return {
            ...step,
            fields: step.fields.map(field => {
              if (field.id === id) {
                return {
                  ...field,
                  validate: { ...field.validate, ...validate }
                };
              }

              return field;
            })
          };
        }

        return step;
      });
    },
    enableKladrForDynamicFields(state){
      // console.log(state.plugins);
      const kladrPluginIndex = state.plugins.findIndex((plugin)=>plugin.type === "kladr" && 
        plugin.config.hasOwnProperty("address") && plugin.config.address === "address_fact");
      if(kladrPluginIndex >= 0){
        let kladrPlugin = state.plugins[kladrPluginIndex];
        const kladrPluginConfig = kladrPlugin.config;
        import(`@/plugins/${kladrPlugin.type}`).then((Plugin)=>{
        const pluginInstance = new Plugin.default(
          kladrPluginConfig,
          this._mutations['form/setValue'][0],
          this._mutations['form/setOptions'][0],
          this._mutations['form/setFieldNotRequired'][0],
          this._mutations['form/setFieldRequired'][0],
        );
        pluginInstance.init();
        });
        
      }
    },
  },
  actions: {
    reset({ commit }) {
      let state = {
        title: "",
        activeStep: 0,
        plugins: [],
        data: null,
        steps: []
      };
      commit("setState", state);
    }
  }
};
